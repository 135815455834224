import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../gatsby-theme-material-ui-top-layout/theme";
import { useI18next, useTranslation, Link } from "gatsby-plugin-react-i18next";
import {
  AppBar,
  Typography,
  Button,
  Box,
  Container,
  Menu,
  MenuItem,
  Toolbar
} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import uLaval from "../images/ULaval.svg";
import pegasus from "../images/pegasus.svg";
import chaire from "../images/chaire.png";
import chaireEn from '../images/chaireEN.png';
import greyboxLogo from "../images/greyboxLogo.png";
import mainLogo from "../images/decision-logo/logo_black.png";
import { navigate } from "gatsby";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    marginLeft: "auto"
  },
  menuBar: {
    color: "#454545",
    backgroundColor: "white",
    height: "90px"
  },
  hamburger: {
    position: "fixed",
    color: "white",
    left: "7px",
    top: "7px",
    textDecoration: "none"
  },
  centeredTitle: {
    marginLeft: "auto"
  },
  hero: {
    height: "20vh",
    backgroundImage: `linear-gradient(to bottom right, ${theme.palette.secondary.main}, ${theme.palette.primary.main}) !important`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "20px",
  },
  heroTitle: {
    fontSize: "3.8rem",
    fontWeight: 700,
    color: "white"
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    height: "20%",
    borderTop: "1px solid #f0f0f2"
  },
  rightReserved: {
    textDecoration: "none",
    color: "#95989a",
    textAlign: "center",
    marginBottom: "10px"
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 0 20px 0"
  },
  linkRight: {
    textDecoration: "none",
    color: "inherit",
    marginLeft: "auto"
  },
  logos: {
    maxWidth: "100%"
  },
  nextPreviousBtn: {
    minWidth: "120px",
    color: "white"
  },
  pegasusLogo: {
    objectFit: "contain",
    height: "130px",
  },
  uLavalLogo: {
    objectFit: "contain",
    width: "200px",
  },
  chaireLogo: {
    objectFit: "contain",
    width: "300px",
  },
  greyboxLogo: {
    objectFit: "contain",
    width: "200px",
  },
  footerLink: {
    textDecoration: "none",
    color: "inherit",
    margin: "0 5px 0 5px",
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  pageLogo: {
    objectFit: "fit",
    paddingTop: "8px",
    height: "90px",
    width: "400px",
    marginLeft: "0"
  },
  smallPageLogo: {
    objectFit: "contain",
    paddingTop: "8px",
    width: "200px",
    marginLeft: "0"
  }
}))

function LanguageSwitcher() {
  const { language, changeLanguage } = useI18next()

  const getLanguage = () => {
    return language == "en" ? { code: "fr", label: "Fr" } : { code: "en", label: "En" }
  }

  const renderLanguageChoice = ({ code, label }) => {
    return <Button key={code} onClick={() => changeLanguage(code)}>{label}</Button>
  }

  return (
    <div>
      {renderLanguageChoice(getLanguage())}
    </div>
  )
}

function MobileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles()
  const { t } = useTranslation()
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navButtons = [
    {
      label: t("Home"),
      path: "/"
    },
    {
      label: t("Trisomy"),
      path: "/trisomies"
    },
    {
      label: t("Screening"),
      path: "/screening"
    },
    {
      label: t("Diagnostic"),
      path: "/diagnostic"
    },
    {
      label: t("About"),
      path: "/about"
    },
    {
      label: t("Find out more"),
      path: "/more-information"
    }
  ]

  return (
    <div>
      <Button className={classes.hamburger}
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}>
        Menu
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {navButtons.map((button) => {
          return (
            <MenuItem key={button.label} onClick={handleClose} color={"inherit"} component={Link} to={button.path}>
              {t(button.label).toUpperCase()}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  );
}

function MenuBar() {
  const classes = useStyles()
  const { t } = useTranslation()
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isPhoneScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const navButtons = [
    {
      label: t("Home"),
      path: "/"
    },
    {
      label: t("Trisomy"),
      path: "/trisomies"
    },
    {
      label: t("Screening"),
      path: "/screening"
    },
    {
      label: t("Diagnostic"),
      path: "/diagnostic"
    },
    {
      label: t("About"),
      path: "/about"
    },
    {
      label: t("Find out more"),
      path: "/more-information"
    }
  ]

  return (
    <Toolbar className={classes.menuBar}>
      <div className={isSmallScreen ? "" : classes.centeredTitle}>
        <Link to="/">
          <img src={mainLogo} alt="logo" className={isPhoneScreen ?  classes.pageLogo : classes.smallPageLogo}></img>
        </Link>
      </div>
      <div className={classes.menuButton}>
        {isSmallScreen ? (
          <>
            {navButtons.map((button) => {
              return (
                <Link key={button.label} className={classes.link} to={button.path}>
                  <Button color="inherit">
                    {t(button.label).toUpperCase()}
                  </Button>
                </Link>
              )
            })}
          </>
        ) : null
        }
        <LanguageSwitcher/>
      </div>
    </Toolbar>
  )
}

export default function Layout(props) {
  const {
    pageTitle,
    next,
    previous = true,
    nextDisabled = false,
    maxWidth = "md"
  } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div className={classes.root}>
      <AppBar position={isSmallScreen ? "sticky" : "static"}>
        <MenuBar/>
      </AppBar>
      {isSmallScreen ? (
          null
      ) :
        <MobileMenu/>
      }
        <header className={classes.hero}>
          <Container maxWidth={maxWidth}>
            <Typography variant={isSmallScreen ? "h1" : "h2"} >
              {pageTitle}
            </Typography>
          </Container>
        </header>
      <Container maxWidth={maxWidth}>
        {props.children}
        <div className={classes.navigation}>
          {previous && (
            <Button
              className={classes.nextPreviousBtn}
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
            >
              {t("Previous").toUpperCase()}
            </Button>
          )}
          {next && (
            <Button 
              className={classes.nextPreviousBtn}
              style={{marginLeft: !previous ? "auto" : "0"}}
              variant="contained"
              color="primary"
              onClick={() => navigate(next)}
              disabled={nextDisabled}
            >
              {t("Next").toUpperCase()}
            </Button>
          )}
        </div>
      </Container>
      <footer className={classes.footer}>
        <Container maxWidth="md">
          <Box display="flex"
               flexDirection="row"
               alignItems="center"
               flexWrap="wrap"
               justifyContent="center"
               margin="auto"
               height="auto">
            <a href="https://www.ulaval.ca/">
              <img src={uLaval} alt="logo" className={classes.uLavalLogo}></img>
            </a>
            <a href="http://pegasus-pegase.ca/">
              <img src={pegasus} alt="logo" className={classes.pegasusLogo}></img>
            </a>
            <a href="http://www.decision.chaire.fmed.ulaval.ca/">
              {i18n.languages[0] === "fr" ? (
                <img src={chaire} alt="logo" className={classes.chaireLogo}></img>
              ) : (
                <img src={chaireEn} alt="logo" className={classes.chaireLogo}></img>
              )}
            </a>
            <a href="https://greybox.ca/">
              <img src={greyboxLogo} alt="logo" className={classes.greyboxLogo}></img>
            </a>
          </Box>
        </Container>
        <a className={classes.rightReserved} href="https://greybox.ca/" >
            {t("© ALL RIGHT RESERVED, GREYBOX SOLUTIONS INC. Developed with help to decision making tool © Université Laval")}
        </a>
      </footer>
    </div>
  )
}
